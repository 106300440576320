import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./AdminRoute";
import Spinner from "../components/Common/Spinner/SpinnerPage";

/***
TEMPLATES
------------------------------- */
import FrontEnd from "../components/Common/Layout/Templates/FrontEnd/Template";

/***
VIEWS
------------------------------- */
//Main Views
import Home from "../components/Main/Home/Home";

//Auth Views
import Logout from "../components/Auth/Logout/Logout";

//Static Pages
import Page403 from "../components/Main/Errors/Page403";
import Page404 from "../components/Main/Errors/Page404";

/*
import Contact from "../components/Contact/Contact";
import Login from "../components/Auth/Login/Login";
import Register from "../components/Auth/Register/Register";
import PassRequest from "../components/Auth/PasswordReset/Request/Request";
import PassReset from "../components/Auth/PasswordReset/Reset/Reset";
import Account from "../components/Account/Account";
import Category from "../components/Shop/Category/Category";
import Product from "../components/Shop/Product/Product/Product";
import ProductReview from "../components/Shop/Product/Review/Review";
import Cart from "../components/Shop/Cart/Cart";
import Checkout from "../components/Shop/Checkout/Checkout";
*/

//*** LAZY LOAD
//Main Views
const Contact = React.lazy(() => import("../components/Contact/Contact"));

//Auth Views
const Login = React.lazy(() => import("../components/Auth/Login/Login"));
const Register = React.lazy(() =>
  import("../components/Auth/Register/Register")
);
const PassRequest = React.lazy(() =>
  import("../components/Auth/PasswordReset/Request/Request")
);
const PassReset = React.lazy(() =>
  import("../components/Auth/PasswordReset/Reset/Reset")
);
const Account = React.lazy(() => import("../components/Account/Account"));

//Shop Views
const Category = React.lazy(() =>
  import("../components/Shop/Category/Category")
);
const Product = React.lazy(() =>
  import("../components/Shop/Product/Product/Product")
);
const ProductReview = React.lazy(() =>
  import("../components/Shop/Product/Review/Review")
);
const Cart = React.lazy(() => import("../components/Shop/Cart/Cart"));
const Checkout = React.lazy(() =>
  import("../components/Shop/Checkout/Checkout")
);

//Recipe views
const Recipes = React.lazy(() =>
  import("../components/Recipes/Recipes/Recipes")
);
const Recipe = React.lazy(() => import("../components/Recipes/Recipe/Recipe"));
const RecipeCategory = React.lazy(() =>
  import("../components/Recipes/Category/Category")
);

const Admin = React.lazy(() => import("./Admin.js"));

const Routes = () => {
  return (
    <Switch>
      <Route>
        <Suspense fallback={<Spinner />}>
          <FrontEnd>
            <Switch>
              <Route exact path="/" component={Home} />

              <Route exact path="/register" component={Register} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <Route
                exact
                path="/password-reset/request"
                component={PassRequest}
              />
              <Route
                exact
                path="/password-reset/reset/:id?"
                component={PassReset}
              />

              <PrivateRoute exact path="/account/:page?" component={Account} />

              <Route exact path="/shop/c/:url_tag?" component={Category} />
              <Route exact path="/shop/p/:url_tag?" component={Product} />
              <Route
                exact
                path="/shop/p/:id/review"
                component={ProductReview}
              />
              <Route exact path="/shop/cart" component={Cart} />
              <Route exact path="/shop/checkout" component={Checkout} />

              <Route exact path="/contact-us" component={Contact} />

              <Route
                exact
                path="/recipes/c/:url_tag?"
                component={RecipeCategory}
              />
              <Route exact path="/recipes/r/:url_tag?" component={Recipe} />
              <Route exact path="/recipes" component={Recipes} />

              <AdminRoute exact path="/saucemaster/*" component={Admin} />

              <Route exact path="/page/403" component={Page403} />
              <Route exact path="/page/404" component={Page404} />

              <Route path="*" component={Page404} />
            </Switch>
          </FrontEnd>
        </Suspense>
      </Route>
    </Switch>
  );
};

export default Routes;
